@tailwind base;
@tailwind components;
@tailwind utilities;

/* You can add global styles to this file, and also import other style files */

body {
  font-family: Inter, sans-serif;
  font-weight: 300;
}
